<template>
  <div class="center" v-if="!firebaseAuth">
    <div class="center text-white">
      <h2>Firebase login</h2>
      <input type="text" v-model="email" placeholder="Email">
      <input type="password" v-model="password" placeholder="Password">
      <button @click="login">Login</button>
      <p v-if="loginError" class="error">{{ loginError }}</p>
    </div>
  </div>
  <div v-else>
    <div class="center" v-if="!started">
      <button @click="startAlgorithm()" class="start-button mb-4">START 🚀</button>
      <div class="flex flex-col mt-5 gap-3 text-left min-w-[365px]">
        <p class="text-white">
          Working with a delay of
          <b><input type="text"
                    v-model.number="options.delay"
                    class="bg-transparent text-center border-b w-[30px] border-white text-white focus:outline-none"
          ></b> seconds
        </p>
        <p class="text-white">
          Messages are displayed for
          <b><input type="text" v-model.number="options.show_duration"
                    class="bg-transparent text-center border-b w-[30px] border-white text-white focus:outline-none"
          ></b> seconds
        </p>

        <button @click="toggleAdvanced" class="text-purple-200 flex items-center gap-2 mt-6">
          Advanced
          <span :class="{'rotate-180': showAdvancedOptions}" class="transition-transform duration-300">
            &#9660;
          </span>
        </button>
        <hr>

        <!-- Geavanceerde opties -->
        <div class="flex flex-col gap-3" v-if="showAdvancedOptions">
          <p class="text-white">
            Working with timeline
            <b><input type="text" v-model="options.timeline"
                      class="bg-transparent text-center border-b w-[30px] border-white text-white focus:outline-none"
            ></b>
          </p>

          <p class="text-white">
            Working with method
            <select v-model="options.method" class="bg-transparent text-left border-b font-bold w-auto border-white text-white focus:outline-none">
              <option value="eventSource">EventSource (Live)</option>
              <option value="watchPassings">Watch Passings (Polling)</option>
            </select>
          </p>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="photo-logo">
        <img src="@/assets/logos/beneva.svg" alt="Beneva logo">
      </div>
      <div v-if="!messagesStarted" class="center">
        <p class="text-white font-semibold text-7xl">Power up<br>with a cheer!</p>
      </div>
      <div v-else class="photo-grid">
        <div class="div1" v-if="activePhotos[1]" :class="{'no-image': !activePhotos[1].image}">
          <img :src="activePhotos[1].image" alt="">
          <div class="image-content">
            <img class="top-right-logo" src="@/assets/logos/beneva.svg" alt="Beneva logo">
            <img class="top-left-logo" src="@/assets/logos/beneva-mississauga.svg" alt="Beneva logo">
            <div class="image-text">
              <p>{{ activePhotos[1].message }}</p>
              <p>- {{ activePhotos[1].name }}</p>
            </div>
          </div>
          <div class="timer-bar"></div>
        </div>
        <div class="div2" v-if="activePhotos[2]" :class="{'no-image': !activePhotos[2].image}">
          <img :src="activePhotos[2].image" alt="">
          <div class="image-content">
            <img class="top-right-logo" src="@/assets/logos/beneva.svg" alt="Beneva logo">
            <img class="top-left-logo" src="@/assets/logos/beneva-mississauga.svg" alt="Beneva logo">
            <div class="image-text">
              <p>{{ activePhotos[2].message }}</p>
              <p>- {{ activePhotos[2].name }}</p>
            </div>
          </div>
          <div class="timer-bar"></div>
        </div>
        <div class="div3" v-if="activePhotos[3]" :class="{'no-image': !activePhotos[3].image}">
          <img :src="activePhotos[3].image" alt="">
          <div class="image-content">
            <img class="top-right-logo" src="@/assets/logos/beneva.svg" alt="Beneva logo">
            <img class="top-left-logo" src="@/assets/logos/beneva-mississauga.svg" alt="Beneva logo">
            <div class="image-text">
              <p>{{ activePhotos[3].message }}</p>
              <p>- {{ activePhotos[3].name }}</p>
            </div>
          </div>
          <div class="timer-bar"></div>
        </div>
        <div class="div4" v-if="activePhotos[4]" :class="{'no-image': !activePhotos[4].image}">
          <img :src="activePhotos[4].image" alt="">
          <div class="image-content">
            <img class="top-right-logo" src="@/assets/logos/beneva.svg" alt="Beneva logo">
            <img class="top-left-logo" src="@/assets/logos/beneva-mississauga.svg" alt="Beneva logo">
            <div class="image-text">
              <p>{{ activePhotos[4].message }}</p>
              <p>- {{ activePhotos[4].name }}</p>
            </div>
          </div>
          <div class="timer-bar"></div>
        </div>
        <div class="div5" v-if="activePhotos[5]" :class="{'no-image': !activePhotos[5].image}">
          <img :src="activePhotos[5].image" alt="">
          <div class="image-content">
            <img class="top-right-logo" src="@/assets/logos/beneva.svg" alt="Beneva logo">
            <img class="top-left-logo" src="@/assets/logos/beneva-mississauga.svg" alt="Beneva logo">
            <div class="image-text">
              <p>{{ activePhotos[5].message }}</p>
              <p>- {{ activePhotos[5].name }}</p>
            </div>
          </div>
          <div class="timer-bar"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getSubmissionByStartNumber,
  updateSubmission,
  loginWithCredentials,
  auth,
  getSubmissionsByStartNumber
} from '@/firebase';
import {urlParameter} from "@/helpers/urlParameter";
import moment from "moment/moment";

export default {
  name: 'photos',
  data() {
    return {
      event_id: '1394',
      environment: process.env.NODE_ENV === 'development' ? 'dev' : 'prod',
      showAdvancedOptions: false,
      firebaseAuth: false,
      started: false,
      messagesStarted: false,
      submissions: {},
      activePhotos: {},
      seenMessages: [],
      options: {
        passings_interval: 3,
        passings_cleanup_interval: 10,
        cheerscreen_check_interval: 1,
        delay: urlParameter.get('delay') ? urlParameter.get('delay') : 0,
        show_duration: urlParameter.get('show_duration') ? urlParameter.get('show_duration') : 9,
        timeline: urlParameter.get('timeline') ? urlParameter.get('timeline') : '',
        method: urlParameter.get('method') ? urlParameter.get('method') : 'watchPassings',
      },
      reformedPassings: [],
      fakeData: [
        {
          "start_number": 123,
          "time": "2025-03-20T10:40:01.208000Z",
          "timeline_name": 2
        },
        {
          "start_number": 4,
          "time": "2025-03-20T10:40:10.208000Z"
        },
        {
          "start_number": 12345,
          "time": "2025-03-20T10:40:12.208000Z"
        },
        {
          "start_number": 12,
          "time": `2025-03-20T10:40:15.208000Z`
        },
        {
          "start_number": 1234,
          "time": "2025-03-20T10:40:20.208000Z"
        },
        {
          "start_number": 8,
          "time": "2025-03-20T10:40:31.208000Z"
        },
      ]
    }
  },
  beforeMount() {
    if (process.env.NODE_ENV === 'development') {
      this.firebaseAuth = true;
    } else {
      auth.onAuthStateChanged(user => {
        if (user && !user.isAnonymous) {
          this.firebaseAuth = true;
        }
      });
    }

    this.updateUrlParameters();
  },
  watch: {
    options: {
      handler() {
        this.updateUrlParameters();
      },
      deep: true, // Zorg ervoor dat alle nested properties worden gecontroleerd
    },
  },
  methods: {
    async login() {
      this.firebaseAuth = await loginWithCredentials(this.email, this.password);
    },
    updateUrlParameters() {
      const params = new URLSearchParams(window.location.search);

      // Specifieke opties die je wilt bijwerken
      const optionsToUpdate = ['delay', 'show_duration'];

      optionsToUpdate.forEach(key => {
        const value = this.options[key];
        if (value !== undefined && value !== '') {
          params.set(key, value); // Voeg of update de parameter
        } else {
          params.delete(key); // Verwijder de parameter als de waarde leeg is
        }
      });

      // Pas de URL aan zonder de pagina te herladen
      window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
    },
    toggleAdvanced() {
      this.showAdvancedOptions = !this.showAdvancedOptions;
    },
    startAlgorithm() {
      if (!this.firebaseAuth) {
        return;
      }

      this.started = true;

      if (this.options.method === 'eventSource') {
        this.listenToPassings();
      } else if (this.options.method === 'watchPassings') {
        // Start watching passings (passings_interval)
        setInterval(() => {
          if (this.environment === 'dev') {
            this.reformPassings(this.fakeData);
          } else {
            this.watchPassings().then(passings => {
              console.log("Passings: ", passings);
              if (!passings || passings.length === 0) {
                return;
              }
              this.reformPassings(passings);
            });
          }
        }, this.options.passings_interval * 1000, (0));
      }

      // Start cleanup passings who are to old (passings_cleanup_interval)
      setInterval(this.removeOldPassing, (this.options.passings_cleanup_interval * 1000), (1));

      setInterval(this.updateCheerscreen, (this.options.cheerscreen_check_interval * 1000), (2));
    },

    listenToPassings() {
      const eventSource = new EventSource('https://api.alpha.tracx.events/v1/web/webhook/passings/sse');
      eventSource.onmessage = (event) => {
        console.log('Message from server:', event);
        if(event.data) {
          try {
            const jsonData = JSON.parse(event.data);
            this.reformPassings(jsonData);
          } catch (error) {
            console.error("Data: ", event.data, "is invalid JSON: ", error);
          }
          this.reformPassings(event.data);
        }
      };
      eventSource.onerror = (error) => {
      };
    },
    async watchPassings() {
      try {
        const response = await this.$api.app.get('v1/web/webhook/passings');
        return response.data.data;
      } catch (error) {
        console.error('Error fetching passings:', error);
        return null;
      }
    },
    reformPassings(data) {
      data.forEach((passing) => {
        const messageStart = moment.utc(passing.time).add(this.options.delay * 1000, 'milliseconds');
        const messageEnd = messageStart.add(this.options.show_duration, 'milliseconds');

        passing = {
          start_number: passing.start_number.toString(),
          message_start: messageStart,
          message_end: messageEnd,
          timeline_name: passing.timeline_name ?? null
        };

        // We need a timeline name
        if (this.options.timeline.length > 0) {
          if (passing.timeline_name && passing.timeline_name.toString() === this.options.timeline) {
            this.reformedPassings.push(passing);
          }
        } else {
          this.reformedPassings.push(passing);
        }
      });
      console.info('Reformed passings to: ', this.reformedPassings);
    },
    removeOldPassing() {
      const now = moment.utc();
      const passings_cleanup_interval = this.options.passings_cleanup_interval * 1000;

      this.reformedPassings = this.reformedPassings.filter(passing => {
        const dif = now.diff(passing.message_start);
        // if (dif > passings_cleanup_interval) {
        //   console.info('Removed this passing - ', passing, ' - Older then ' + this.options.passings_cleanup_interval + ' seconds');
        // }
        return dif <= passings_cleanup_interval;
      });
    },
    async updateCheerscreen() {
      try {
        const passings = this.reformedPassings;
        for (const passing of passings) {
          const now = moment.utc();

          // If message should start or should have started & if end of message time has not passed
          if (now.clone().add(1, 'seconds').isSameOrAfter(passing.message_start) &&
            now.clone().subtract(1, 'seconds').isSameOrBefore(passing.message_end)) {

            let submission = await getSubmissionsByStartNumber(passing.start_number, this.event_id);
            submission = submission && submission.length ? submission[0] : null;

            if (submission && !submission.watched) {
              if (!this.messagesStarted) {
                this.messagesStarted = true;
              }

              console.info('Showing submission:', submission);
              const emptyIndex = this.findOutdatedPhoto();
              if (emptyIndex !== -1) {
                this.activePhotos[emptyIndex] = {
                  name: submission.name_cheerer,
                  message: submission.message,
                  image: submission.image,
                  ended: passing.message_end,
                  isOutdated: false
                };
                setTimeout(() => {
                  this.activePhotos[emptyIndex].isOutdated = true;
                }, this.options.show_duration * 1000);
              }
              await updateSubmission(submission.id, {watched: true});
            } else {
              console.info('Submission already shown:', submission);
            }
          }
        }
      } catch (error) {
        console.error('Error in updateCheerscreen:', error);
      }
    },
    findOutdatedPhoto() {
      let oldestIndex = -1;
      let oldestTime = null;

      if (this.activePhotos[1]?.isOutdated) {
        return 1;
      }

      for (let i = 1; i <= 5; i++) {
        if (!this.activePhotos[i] || this.activePhotos[i].isOutdated) {
          const endedTime = this.activePhotos[i]?.ended || moment.utc(0); // Gebruik een oude tijd als fallback

          if (oldestTime === null || endedTime.isBefore(oldestTime)) {
            oldestTime = endedTime;
            oldestIndex = i;
          }
        }
      }
      return oldestIndex;
    }
  },
}
</script>

<style lang="scss" scoped>

.photo-logo {
  width: 100%;

  img {
    display: block;
    margin: 40px auto;
    max-width: 350px;
    max-height: 72px;
  }
}

.photo-grid {
  width: calc(100% - 48px);
  max-width: 1500px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 330px);
  gap: 24px;
  position: relative;

  * {
    box-sizing: border-box;
  }

  > div {
    border-radius: 12px;
    position: relative;
    overflow: hidden;

    > img {
      background-color: #222222;
    }

    .image-content {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.7315519957983193) 0, rgba(255, 255, 255, 0) 100%);
      }

      .image-text {
        position: absolute;
        bottom: 12px;
        left: 12px;

        p {
          margin: 0;
          font-weight: 700;
          color: white;
        }

        p:first-child {
          font-size: 34px;
          line-height: 42px;
          letter-spacing: -0.68px;
          margin-bottom: 16px;
        }

        p:last-child {
          font-size: 24px;
        }
      }

      > img {
        position: absolute;
        object-fit: contain;
      }

      .top-right-logo {
        right: 12px;
        width: 125px;
        top: 28px;
        height: 30px;
      }

      .top-left-logo {
        top: 12px;
        left: 12px;
        width: 140px;
        height: 67px;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .div1 {
    grid-area: 1 / 1 / 3 / 3;
    border-radius: 24px;

    .image-content {
      .image-text {
        bottom: 32px;
        left: 32px;

        p:first-child {
          font-size: 72px;
          line-height: 87px;
          letter-spacing: -1.44px;
        }

        p:last-child {
          font-size: 48px;
        }
      }

      .top-right-logo {
        top: 57px;
        right: 24px;
        width: 220px;
        height: 55px;
      }

      .top-left-logo {
        top: 24px;
        left: 24px;
        width: 250px;
        height: 120px;
      }
    }
  }

  .div2 {
    grid-area: 1 / 3 / 2 / 4;
  }

  .div3 {
    grid-area: 1 / 4 / 2 / 5;
  }

  .div4 {
    grid-area: 2 / 3 / 3 / 4;
  }

  .div5 {
    grid-area: 2 / 4 / 3 / 5;
  }
}

.no-image {
  > img {
    display: none;
  }

  .image-content::before {
    background: linear-gradient(180deg, rgba(36, 36, 36, 0.00) -9.83%, #242424 89.7%), #402D85 !important;
  }

  .image-text {
    p:first-child {
      margin-bottom: 80px !important;
      width: 300px;
      text-align: center;
    }
  }
}

.no-image.div1 {
  .image-text {
    p:first-child {
      width: 600px;
      margin-bottom: 140px !important;
    }
  }
}
</style>
