import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {
  doc, getDoc, updateDoc, addDoc, collection, getFirestore, query, where, getDocs, connectFirestoreEmulator
} from "firebase/firestore";
import {
  getAuth, signInAnonymously, signOut, signInWithEmailAndPassword, connectAuthEmulator
} from "firebase/auth";
import {
  getStorage, ref, uploadBytesResumable, getDownloadURL, connectStorageEmulator
} from "firebase/storage";
import * as XLSX from "xlsx";

const firebaseConfig = {
  apiKey: "AIzaSyDoa6rnk5YBsgNP7redUsvbi72rGMFGtPo",
  authDomain: "cheerzone-5eb66.firebaseapp.com",
  projectId: "cheerzone-5eb66",
  storageBucket: "cheerzone-5eb66.firebasestorage.app",
  messagingSenderId: "248225192430",
  appId: "1:248225192430:web:b43f85c4723946fb7196ff",
  measurementId: "G-CML4BHQ1Q7"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);


if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 1200);
  connectStorageEmulator(storage, "localhost", 9199);

  await signInAnonymously(auth);
}


export async function loginWithCredentials(email, password) {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    console.log("Succesvol ingelogd als selectie@sportunity.nu");
    return true;
  } catch (error) {
    console.error("Login mislukt:", error.message);
    return false;
  }
}

export async function signOutFirebase() {
  signOut(auth).then(() => {
    console.log('Signed out');
  }).catch((error) => {
    console.log('Error signing out: ', error);
  });
}

export {auth, db, storage}

export async function getSubmissionsByStartNumber(startNumber, event_id) {
    try {
        // Create a query against the 'submissions' collection
        const q = query(
          collection(db, "submissions"),
          where("start_number", "==", startNumber),
          where("event_id", "==", event_id)
        );

        // Execute the query
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            return null;
        } else {
          let foundData = [];
            querySnapshot.forEach((doc) => {
              let data = doc.data();
              data.id = doc.id;
              foundData.push(data);
            });

            return foundData;
        }
    } catch (error) {
        console.error("Error retrieving document: ", error);
    }
}

export async function resetSubmissions() {
  try {
    // Create a query against the 'submissions' collection
    const q = query(collection(db, "submissions"), where("event_id", "==", "1394"));

    // Execute the query
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return null;
    } else {
      querySnapshot.forEach((doc) => {
        updateSubmission(doc.id, {watched: false})
      });

      return true;
    }
  } catch (error) {
    console.error("Error retrieving document: ", error);
  }
}

if (process.env.NODE_ENV === 'development') {
  resetSubmissions();
}

export async function updateSubmission(recordId, updatedData) {
  try {
    const recordRef = doc(db, "submissions", recordId);
    await updateDoc(recordRef, updatedData);
    return true;
  } catch (error) {
    console.error(`Error updating record ${recordId}: `, error);
    return false;
  }
}

export async function exportSubmissionsToExcel() {
  try {
    // Haal alle submissions op
    const q = query(collection(db, "submissions"));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.warn("Geen submissions gevonden.");
      return;
    }

    // Converteer submissions naar een array van objecten
    const submissions = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id; // Voeg document-ID toe
      submissions.push(data);
    });

    // Maak een worksheet en workbook
    const worksheet = XLSX.utils.json_to_sheet(submissions);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Submissions");

    // Genereer een bestand en download het
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    // Creëer een download-link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "submissions.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    console.log("Excel-bestand gegenereerd en gedownload.");
  } catch (error) {
    console.error("Fout bij exporteren van submissions: ", error);
  }
}

