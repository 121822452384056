<template>
  <div v-if="!firebaseAuth">
    <div class="center text-white">
      <h2>Firebase login</h2>
      <input type="text" v-model="email" placeholder="Email">
      <input type="password" v-model="password" placeholder="Password">
      <button @click="login">Login</button>
      <p v-if="loginError" class="error">{{ loginError }}</p>
    </div>
  </div>
  <div v-else>
    <div v-if="!timelineName">
      <div class="center text-white">
        <h1>Missing timeline name</h1>
        <p>I'm missing a timeline_name parameter in the url. For example:<br>
          <i>https://juichscherm.tracx.events/videos?timeline_name=1</i>
        </p>
      </div>
    </div>
    <div class="center" v-else-if="!started">
      <button @click="startAlgorithm()" class="start-button">START 🚀</button>
      <p v-if="!delay" class="text-white"><i>Working with no delay right now</i></p>
    </div>
    <div v-else>
      <img v-if="!this.videoIsPlaying" class="default-background-image" src="../assets/default-background-image.svg">
      <img v-if="this.videoIsPlaying" class="cheerzone-video-bar" src="../assets/cheerzone-bar.svg">
      <div class="video-div" id="videoDiv" :style="this.videoIsPlaying ? 'z-index:2' : 'z-index:0' ">
        <video :src="videoURL" autoplay ref="videoPlayer"/>
        <!--            <video src="https://firebasestorage.googleapis.com/v0/b/cheerzone-5eb66.firebasestorage.app/o/uploads%2Fmidwinter-2025%2FzQ5ZrrfkbqcrlTy5jjLgRgAPfhu2-IMG_3906.mov?alt=media&token=3848ca4a-bfdd-44c4-84db-65e5388c17b7"-->
        <!--                   autoplay ref="videoPlayer"/>-->
        <img class="video-placeholder" v-if="videoURL" src="../assets/video-placeholder.svg">
      </div>
    </div>
  </div>
</template>

<script>

import {getSubmissionsByStartNumber, updateSubmission, loginWithCredentials, auth} from '../firebase';
import moment from "moment";

export default {
  name: 'videos',
  data() {
    return {
      event_id: '1304',
      firebaseAuth: false,
      email: "",
      password: "",
      delay: this.getDelayFromUrl(),
      timelineName: this.getTimelineName(),
      videoPlayer: null,
      started: false,
      videoIsPlaying: false,
      videoURL: null,
      data: [],
      fakeData: [
        {
          "participant_id": 32990088,
          "start_number": "132",
          "time": "2025-02-01 13:00:55.000000",
          "timeline_name": "SPLIT_UTC"
        }
      ]
    }
  },
  mounted() {
    if (process.env.NODE_ENV === 'development') {
      this.firebaseAuth = true;
    } else {
      auth.onAuthStateChanged(user => {
        if (user && !user.isAnonymous) {
          this.firebaseAuth = true;
        }
      });
    }
  },
  methods: {
    async login() {
      this.firebaseAuth = await loginWithCredentials(this.email, this.password);
    },
    getDelayFromUrl() {
      const delayFromUrl = this.$route.query.delay;
      return delayFromUrl && !isNaN(delayFromUrl) ? parseInt(delayFromUrl, 10) * 1000 : 0;
    },
    getTimelineName() {
      let timelineName = this.$route.query.timeline_name;
      if (timelineName) {
        return timelineName.toString();
      }

      return null;
    },
    startAlgorithm() {
      this.started = true;

      this.$nextTick(() => {
        if (this.$refs.videoPlayer) {
          this.videoPlayer = this.$refs.videoPlayer;
          this.videoPlayer.addEventListener('ended', this.videoEnded);
        } else {
          console.error('videoPlayer ref is niet gevonden');
        }
      });

      setInterval(() => {
        if (process.env.NODE_ENV === 'development') {
          this.reformPassings(this.fakeData);
        } else {
          this.getPassings().then(passings => {
            console.info('Reveived passings: ', passings);
            if (passings.length === 0) {
              return;
            }
            this.reformPassings(passings);
          });
        }
      }, 3000);

      this.processPassings();
      this.removeOldPassing();
    },
    removeOldPassing() {
      const now = moment.utc();

      this.data = this.data.filter(passing => {
        const dif = now.diff(passing.videoStart);
        if (dif > 60000) {
          console.info('Removed this passing - ', passing, ' - Older then 1 minute');
        }
        return dif <= 60000;
      });

      setTimeout(this.removeOldPassing, 60000);
    },
    async getPassings() {
      try {
        const response = await this.$api.app.get('v1/web/webhook/passings');
        return response.data.data;
      } catch (error) {
        console.error('Error fetching passings:', error);
      }
    },
    reformPassings(data) {
      data.forEach((passing) => {
        const videoStart = moment.utc(passing.time).add(this.delay, 'milliseconds');
        passing.videoStart = videoStart;
        passing.videoStartFormat = videoStart.format;

        // We need a timeline name
        if (this.timelineName) {
          if (passing.timeline_name && passing.timeline_name.toString() === this.timelineName) {
            this.data.push(passing);
          }
        }
      });
      console.info('Reformed passings to: ', this.data);
    },
    async processPassings() {
      const now = moment.utc();
      let activeIndex
      const activePassing = this.data.find((item, index) => {
        if (!item) {
          return;
        }
        let videoStart = item.videoStart;
        activeIndex = index;

        return now.clone().subtract(1, 'seconds').isSameOrBefore(videoStart) && now.clone().add(1, 'seconds').isSameOrAfter(videoStart);
      });

      if (null === activePassing || undefined === activePassing) {
        setTimeout(this.processPassings, 1000);
      }

      if (activePassing) {
        let submission = await getSubmissionsByStartNumber(activePassing.start_number.toString(), this.event_id);
        submission = submission && submission.length ? submission[0] : null;

        if (submission) {
          this.videoURL = submission.video;
          this.playVideo();
          this.markPassingAsShown(submission);
        } else {
          delete this.data[activeIndex];

          this.processPassings();
        }
      }
    },
    markPassingAsShown(submission) {
      updateSubmission(submission.id, {watched: true})
    },
    playVideo() {
      this.videoIsPlaying = true;
      this.videoPlayer.load();
    },
    videoEnded() {
      this.videoIsPlaying = false;
      this.videoURL = null;

      setTimeout(this.processPassings, 200);
    }
  }
}
</script>
