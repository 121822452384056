<template>
    <router-view />
</template>

<style lang="scss">
$primary-color: #402D85;
$primary-color-light: #b3a8d1;

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $primary-color;
}

h1, h2, h3, h4, h5, h6, p {
  font-family: "aktiv-grotesk", sans-serif;
  font-style: normal;
}

.default-background-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.cheerzone-video-bar {
  width: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.start-button {
  display: flex;
  flex-direction: column;
  background-color: $primary-color;
  color: white;
  border: 2px solid white;
  border-radius: 10px;
  padding: 20px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: $primary-color;
  }
}

.start-form {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  font-family: "aktiv-grotesk", sans-serif;
  border: 1px solid white;
  border-radius: 10px;
  min-width: 500px;

  label {
    padding: 10px 15px;
    color: white;
    border-right: 2px solid white;
    font-size: 18px;
  }
  input {
    padding: 10px 15px;
    border: none;
    color: white;
    background: none;
    flex-grow: 1;
    font-size: 16px;
    line-height: 17px;

    &::placeholder {
      color: $primary-color-light;
      opacity: 0.5;
    }
  }
}

.video-div {
  position: relative;
  width: 100%;
  height: 100vh;
  video {
    bottom: 20%;
    border:none;
    width:100%;
    height: 83%;
    position:absolute;
    left:0;
    top:0;
    overflow:hidden;
    background-color: $primary-color;
  }
  .video-placeholder {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 2;
  }
}
</style>
