import axios from 'axios';

const app = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    Accept: 'application/json',
  },
});



app.interceptors.request.use((config) => {
  return config;
}, error => Promise.reject(error));

app.interceptors.response.use((response) => response, (err) => {
  if (axios.isCancel(err)) {
    return Promise.reject(err.response);
  }

  const response = err.response;

  return Promise.reject(response);
});

export default {
  app
};
